<template>
  <b-card-code title="PPP > Secrets">
    <div class="row">
      <div class="col-md-4">
        <b-button
          variant="primary"
          :to="{ name: 'isp-user-name-create' }"
        >
          <feather-icon icon="PlusIcon" />
          Add New
        </b-button>
        <b-button
          class="ml-2"
          variant="primary"
          :to="{ name: 'isp-user-name-create1' }"
        >
          <feather-icon icon="PlusIcon" />
          Add New V2
        </b-button>
      </div>
      <div class="col-md-5">
        <h3>
          <span style="color: #756bea">
            Total Bill: {{ total_sale_value }} </span>&nbsp;
          <span style="color: green"> Active: {{ total_active_ppp }} </span>&nbsp;
          <span style="color: red"> Inactive: {{ total_inactive_ppp }} </span>&nbsp;
        </h3>
      </div>
      <div class="col-md-3">
        <b-form-group>
          <div class="d-flex align-items-center">
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
      :sort-options="{
        enabled: true,
        // initialSortBy: {field: 'last_app_hit', type: 'asc'}
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'live'"
          class="text-nowrap"
        >
          <b-link
            :to="{
              name: 'isp-user-get-live-data',
              params: { usernameId: props.row.id },
            }"
            class="font-weight-bold"
          >
            <span
              v-if="props.row.is_live == 'true'"
              class="bullet bullet-sm mr-1 bullet-success"
            />
            <span
              v-if="props.row.is_live == false"
              class="bullet bullet-sm mr-1 bullet-danger"
            />
            get data

          </b-link>
        </div>
        <div
          v-if="props.column.field === 'user_name'"
          class="text-nowrap"
        >
          <b-link
            :to="{
              name: 'isp-user-name-edit',
              params: { usernameId: props.row.id },
            }"
            class="font-weight-bold"
          >
            <!-- <span
              v-if="props.row.is_live == 'true'"
              class="bullet bullet-sm mr-1 bullet-success"
            />
            <span
              v-if="props.row.is_live == false"
              class="bullet bullet-sm mr-1 bullet-danger"
            /> -->
            {{ props.row.user_name }}

          </b-link>
        </div>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'last_app_hit'">
          {{ props.row.last_app_hit }}
        </span>
        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge>
            {{ props.row.status }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>

        <div
          v-if="props.column.field === 'action'"
          class="text-nowrap"
        >
          <b-link
            :to="{
              name: 'isp-user-name-edit-v2',
              params: { id: props.row.id },
            }"
            class="font-weight-bold"
          >
            <el-button
              type="info"
              size="mini"
              icon="el-icon-edit"
              circle
            />

          </b-link>
        </div>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Showing 1 to </span>
            <b-form-select
              v-model="pageLength"
              :options="['100', '300', '500','1000']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BLink, BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'
import { USERNAME_LIST } from '@core/services/api.js'
import $ from 'jquery'

export default {
  components: {
    BLink,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BButton,
    BDropdownItem,
  },
  data() {
    return {
      pageLength: 100,
      dir: false,
      isp_id: null,
      columns: [
        {
          label: 'PPP Username',
          field: 'user_name',
          // filterOptions: {
          //   enabled: false,
          //   placeholder: 'Search User Name',
          // },
        },
        {
          label: 'Live data',
          field: 'live',
          // filterOptions: {
          //   enabled: false,
          //   placeholder: 'Search User Name',
          // },
        },
        {
          label: 'Package',
          field: 'package_name',
          // filterOptions: {
          //   enabled: true,
          //   placeholder: 'Search Package',
          // },
        },
        {
          label: 'Monthly Bill',
          field: 'package_price',
          // filterOptions: {
          //   enabled: true,
          //   placeholder: 'Search Price',
          // },
        },
        {
          label: 'Paired',
          field: 'users.pair_code',
          // filterOptions: {
          //   enabled: true,
          //   placeholder: 'Search Pair',
          // },
        },
        {
          label: 'Router',
          field: 'router_name',
          // filterOptions: {
          //   enabled: true,
          //   placeholder: 'Search Router',
          // },
        },
        {
          label: 'Last App Hit',
          field: 'last_app_hit',
          // filterOptions: {
          //   enabled: true,
          //   placeholder: 'Last Login',
          // },
          // sortable: true,
        },
        {
          label: 'Name',
          field: 'meta.customer_name',
        },
        {
          label: 'Mobile 1',
          field: 'meta.mobile_no',
        },
        {
          label: 'Mobile 2',
          field: 'meta.alternative_mobile_no',
        },
        {
          label: 'Status',
          field: 'status',
          // filterOptions: {
          //   enabled: true,
          //   placeholder: 'Search Status',
          // },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      total_sale_value: null,
      total_active_ppp: null,
      total_inactive_ppp: null,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {},
  mounted() {
    this.isp_id = $('#ispListId option:selected').val()
    this.userNameList(this.isp_id)
    const instance = this
    $('#ispListId').change(function () {
      this.isp_id = $(this).children('option:selected').val()
      instance.userNameList(this.isp_id)
    })
  },
  methods: {
    userNameList(ispId) {
      this.$http
        .get(`${process.env.VUE_APP_BASEURL + USERNAME_LIST}?isp_id=${ispId}`)
        .then(res => {
          this.rows = res.data.data.ppp_list
          this.total_sale_value = res.data.data.total_sale_value
          this.total_active_ppp = res.data.data.total_active_ppp
          this.total_inactive_ppp = res.data.data.total_inactive_ppp
        })
    },
    refreshStatus() {
      this.userNameList(this.isp_id)
    },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
